import {AppRoutes} from "~/utils/other/AppRoutes";
import {userStore} from "~/store/userStore";
import {navigateTo} from "#app";
import {companyStore} from "~/store/companyStore";
import {UserRole} from "~/utils/enums/UserRole";

export default defineNuxtRouteMiddleware(async (to, from) => {
    setTimeout(()=> {
        const useUserStore = userStore();
        const useCompanyStore = companyStore();
        const loggedInUser = useUserStore.getLoggedInUser;
        if (loggedInUser.id) {
            const companyId = useCompanyStore.getCompanyId;
            const currentCompanyRoles = loggedInUser.allRoles[companyId] ?? [];
            const hasAdminRole = currentCompanyRoles.find((item: any) => item.RoleId == UserRole.CompanyAdmin);
            if (!hasAdminRole) {
                return navigateTo({path: AppRoutes.Unauthorized, replace: true});
            }
        } else {
            return navigateTo({path: AppRoutes.Unauthorized, replace: true});
        }
    }, 200);
})